import Rails from '@rails/ujs';
import * as WebAuthnJSON from '@github/webauthn-json';
import jquery from 'jquery';
import prism from 'prismjs';
import 'bootstrap';

require('select2')();

window.WebAuthnJSON = WebAuthnJSON;
window.Rails = Rails;
window.$ = jquery;
window.jQuery = $;
window.Prism = prism;
